import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState, useCallback } from 'react';

import { useCurrentUser } from '@/auth/useCurrentUser';

import LoadingSpinner from '@/components/feedback/LoadingSpinner';
import { useLogUserSessionMutation } from '@/generated/graphql';
import { handleError } from '@/utils/errors';

export const WithUserSession = ({ children }: { children: ReactNode }) => {
  const [userSessionData, setUserSessionData] = useState<{
    id: string;
    isReady: boolean;
  } | null>(null);

  const currentUser = useCurrentUser();
  const router = useRouter();

  const [logUserSessionMutation] = useLogUserSessionMutation();

  const logSession = useCallback(
    async userSessionId => {
      await logUserSessionMutation({
        variables: {
          lastPath: router.asPath,
          userSessionId: userSessionId || userSessionData?.id,
        },
      })
        .then(res => {
          if (!res.data?.logUserSession?.id) {
            setUserSessionData({
              id: '',
              isReady: true,
            });

            return;
          }

          const normalizedUserSessionId =
            (userSessionData?.id &&
              (res.data.logUserSession.id === userSessionData.id
                ? userSessionData.id
                : res.data.logUserSession.id)) ||
            res.data.logUserSession.id;

          if (!normalizedUserSessionId) {
            handleError(
              new Error(
                `No session ID for ${currentUser.email}. N_USI: ${normalizedUserSessionId}`
              ),
              true
            );

            return;
          }

          setUserSessionData({
            id: normalizedUserSessionId,
            isReady: true,
          });

          localStorage.setItem('userSessionId', res.data.logUserSession.id);
        })
        .catch(error => {
          handleError(error, true);
        });
    },
    [userSessionData?.id, logUserSessionMutation, router.asPath]
  );

  useEffect(() => {
    const userSessionId = localStorage.getItem('userSessionId');

    logSession(userSessionId);

    setUserSessionData({
      id: userSessionId || '',
      isReady: true,
    });
  }, []);

  useEffect(() => {
    if (!currentUser?.isLoggedIn) return;
    if (!userSessionData?.isReady) return;
    let interval: NodeJS.Timeout | null = null;

    const startPolling = () => {
      if (!interval) {
        interval = setInterval(logSession, 7500);
      }
    };

    const stopPolling = () => {
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        stopPolling();
      } else {
        startPolling();
      }
    };

    startPolling();
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      stopPolling();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [userSessionData?.isReady, logSession, currentUser?.isLoggedIn]);

  if (!userSessionData?.isReady) return <LoadingSpinner />;

  return <>{children}</>;
};
